import React from "react";
import { graphql, Link } from "gatsby";
import { ReactComponent as SvgErrorLogo } from "static/assets/brand/shapes/Juto_404.svg";
import MainLayout from "../components/Layout/layout";

const ErrorPage = (props) => {
  const { location } = props;

  return (
    <MainLayout location={location}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center py-5">
            <SvgErrorLogo className="col-4 mb-2" />
            <h1 className="bracket-text mb-2">404 - Seite nicht gefunden</h1>
            <Link to="/" className="text-l">Zurück zur Homepage</Link>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export const query = graphql`
  query ErrorPageQuery {
    page: wordpressPage(slug: { eq: "404" }) {
      title
      slug
    }
  }
`;

export default ErrorPage
